@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

	

/* Name: Banana Yellow
Hex: #eff059
RGB: (255, 226, 31)
CMYK: 0, 0.113, 0.878, 0

Name: Royal Blue
Hex: #032cbf
RGB: (62, 99, 219)
CMYK: 0.716, 0.547, 0, 0.141

Name: X11 Gray
Hex: #8195df
RGB: (187, 187, 190)
CMYK: 0.015, 0.015, 0, 0.254 */